$(function() {

	$(".c-experince-people .experience").slidehide({
		"x":1, "y":0, "alpha":true, "auto":10000,
		"slides":".c-citation",
		"contHeight":true,
		"grouping": 1,
		"buttons":"#slider4",
		"touch":true
	});

});
