(function($) {


	$.fn.initEffects = function() {

		var $items = this.find(".items");

		$items.click(function() {

			if ($(this).is(".open")){

				$(this).removeClass("open");

			}else{

				$(this).addClass("open");

			}

		});

		var $header = this.find(".header");
		$header.waypoint(function(direction) {
				if (direction == "down") {
					$(this.element).addClass("container").closest(".header-out").addClass("fixed");
				} else {
					$(this.element).removeClass("container").closest(".header-out").removeClass("fixed");
				}


			},
			{
				offset: 0
			}

		)

		return this;


	}


})(jQuery);
