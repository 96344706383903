
(function($) {


	$.fn.initOpenForm = function() {

		this.click(function() {

			$(".forms-radek-container").toggleClass("open");

		});


		return this;

	}


})(jQuery);

$(function() {

	$("#open-form").initOpenForm();


});
