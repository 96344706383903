(function($) {


	$.fn.initRecipe = function() {

		this.click(function() {

			if ($(this).parent().parent().is(".open")){

				$(this).parent().parent().removeClass("open");


			}else{


				$(this).parent().parent().addClass("open");

			}

		});


		return this;

	}


})(jQuery);

$(function() {

	$(".c-recipe .item .button").initRecipe();

	//$('#recipe-wrap').columnize({columns: 2});

});
