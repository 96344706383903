(function($) {

	$.fn.initClickFilter = function() {

		this.click(function() {

			$("#filter-content").toggleClass("on");
			$(".filter-button").toggleClass("on");

		});

		return this;

	}


	$.fn.initCloseFilter = function() {


		this.click(function() {

			$("#filter-content").removeClass("on");
			$(".filter-button").removeClass("on");

		});

		return this;

	}

})(jQuery);

$(function() {

	$("#article-filtr").initClickFilter();
	$("#close").initCloseFilter();



});
