(function($) {

	$.fn.initBookMakersClick = function() {

		this.click(function() {

			var id = (this).getAttribute("data-id");

			if ($(this).is(".on")){

				$(this).removeClass("on");

				$(".bookmarks-content > div").removeClass("active");

			}else{

				$(".bookmarks-item-in .items").removeClass("on");

				$(this).addClass("on");

				$(".bookmarks-content > div").removeClass("active");

				$(".bookmarks-content .slide-"+id).addClass("active");

				$(".bookmarks-item-in .item-"+id).addClass("on");

			}

		});

		return this;

	}

})(jQuery);



$(function() {

	$(".bookmarks-item-in .items").initBookMakersClick();
	$(".bookmarks-item-in .items").first().addClass("on");
	$(".bookmarks-content div.slide").first().addClass("active");

	$(document).on('click', '.inp-count__item', function(e) {
		e.preventDefault();

		var $this = $(this),
			$closest = $this.closest('.inp-count'),
			$inp = $closest.find('.inp-text'),
			val = Number($inp.val()) + $this.data('step');

		if (val > 0) {
			$inp.val( val );
			$inp.trigger('input');
		}
	})
		.on('change', '.inp-text', function() {
			var $this = $(this),
				val = Number($this.val());

			if (val<0) {
				$this.val(1);
				$this.trigger('input');
			}
		});

});
