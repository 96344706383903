(function ($) {

    /*
     $.fn.initReference = function() {

     this.click(function() {

     if ($(this).is(".open")){

     $(this).removeClass("open");

     }else{

     $(this).addClass("open");

     }

     });


     return this;

     }
     */

})(jQuery);


$(function () {

  var hp_slider =  $(".products-in-title").slidehide({
        "x": 1, "y": 0, "alpha": true, "auto": 10000,
        "slides": ".product-item",
        "arrowNext": "#arrowNext3",
        "arrowPrev": "#arrowPrev3",
        "contHeight": true,
        "autoStop": true,
        "grouping": function () {
            var sirka = $(window).width();
            if (sirka <= 1106) {
                return 2;

            } else {
                return 3;
            }
        },
        "buttons": "#slider3",
        "touch": true
    });


    $('.slider-qty-changer').mousedown(function (event) {

        event.preventDefault();
        event.stopPropagation();
        event.stopImmediatePropagation();
    });

    $('.slider-product-item').mouseenter(function (event) {
        console.log('ENTER');
        console.log($(this).parent().parent().parent().next('.container').find('.slider a.on'));


        $(this).parent().parent().parent().next('.container').find('.slider a.on').trigger('click');
    })
    $('.slider-product-item').mouseleave(function (event) {
        console.log('LESVE');
    })


});



