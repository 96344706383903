(function($) {

	/*
	$.fn.initReference = function() {

		this.click(function() {

			if ($(this).is(".open")){

				$(this).removeClass("open");

			}else{

				$(this).addClass("open");

			}

		});


		return this;

	}
	*/

})(jQuery);


$(function() {

	$(".c-experiences-in ").slidehide({
		"x":1, "y":0, "alpha":true, "auto":10000,
		"slides":".item",
		"arrowNext":"#arrowNext1",
		"arrowPrev":"#arrowPrev1",
		"contHeight":true,
		"grouping": function(){
			var sirka = $(window).width();
			if (sirka < 1000) {
				return 1;

			}else {
				return 3;
			}
		},
		"buttons":"#slider1",
		"touch":true
	});

});


$(function() {

	$(".experince-people-in").slidehide({
		"x":1, "y":0, "alpha":true, "auto":10000,
		"slides":".c-citation",
		"arrowNext":"#arrowNext4",
		"arrowPrev":"#arrowPrev4",
		"contHeight":true,
		"grouping": 1,
		"buttons":"#slider4",
		"touch":true
	});

});
