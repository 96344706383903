$(function() {

	var $el = {
		status: {
			menu: $('.jq-menu-status'),
			search: $('.jq-search-status'),
			langs: $('.jq-langs-status')
		},
		searchinput: $('.jq-search-input')
	};

	$el.status.panels = $el.status.menu.add($el.status.search);

	$el.status.all = $el.status.menu.add($el.status.search).add($el.status.langs);

	function resetStatus() {

		$el.status.all.prop('checked', false).trigger('change');

	}

	var resetStatusBreakpoint = window.matchMedia('(min-width: 1000px)'); // less variable @breakpointMd

	resetStatus(resetStatusBreakpoint);

	resetStatusBreakpoint.addListener(resetStatus);

	$el.status.panels.change(function() {
		var $asd = $el.status.panels.not(this);

		if (this.checked) {
			if (this == $el.status.search[0]) {
				$el.searchinput.focus();
			}

			if ($asd.is(':checked')) {
				$asd.prop('disabled', true);
			}

			$(document).on('keyup.closeMenuOnEscape', { status: this }, closeMenuOnEscape);
		} else {
			if (this == $el.status.search[0]) {
				$el.searchinput.val('').blur();
			} else if (this == $el.status.menu[0]) {
				$('.jq-langs-status').prop('checked', false);
			}

			if ($asd.is(':disabled')) {
				$asd.prop('disabled', false);
			}

			$(document).off('keyup.closeMenuOnEscape', closeMenuOnEscape);
		}
	});

	function closeMenuOnEscape(event) {
		if (event.keyCode === 27) {
			var args = event.data,
				status = args.status;
			$(status).prop('checked', false).trigger('change');
		}
	}

});
