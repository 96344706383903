/*
(function($) {

	$.fn.initArticlesClick = function() {

		this.click(function() {

			$(".filter .filter-item").removeClass("on");
			$(this).addClass("on");

			var id = (this).getAttribute("data-id");

			$(".filter-content").removeClass("on");
			$(".filter-content-"+id).addClass("on");

		});

		return this;

	}

})(jQuery);

*/

$(function() {

	//$(".filter .filter-item").initArticlesClick();

	$(".c-articles-in").slidehide({
		"x":1, "y":0, "alpha":true, "auto":10000,
		"slides":".item",
		"arrowNext":"#arrowNext2",
		"arrowPrev":"#arrowPrev2",
		"contHeight":true,
		"grouping": function(){
			var sirka = $(window).width();
			if (sirka < 780) {
				return 1;

			 }else {
				return 3;
			}
		},
		"buttons":"#slider2",
		"touch":true
	});

});

